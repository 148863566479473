var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Display',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Avatars are a BootstrapVue custom component, and are typically used to display a user profile as a picture, an icon, or short text. "),_c('code',[_vm._v("<b-avatar>")]),_vm._v(" provides several props for customizing its appearance such as color variant and roundness, and optionally supports acting as a button, link or "),_c('a',{attrs:{"href":"https://bootstrap-vue.org/docs/reference/router-links","target":"_blank"}},[_c('strong',[_vm._v("router link")])]),_vm._v(". ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('image-content')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('icon-content')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('text-content')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('variants')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('sizing')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('square')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('rounding')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('button-avatar')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('link-avatar')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('badge')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('badge-content')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('badge-positioning')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('group')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('group-overlap')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }